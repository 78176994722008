/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            d="M14.187 12.49a6.7 6.7 0 0 0-1.44-2.133 6.7 6.7 0 0 0-2.133-1.44l-.022-.009a4.428 4.428 0 1 0-7.021-3.59c0 1.478.723 2.787 1.836 3.592l-.022.01a6.7 6.7 0 0 0-2.134 1.438 6.7 6.7 0 0 0-1.966 4.6.143.143 0 0 0 .143.147H2.5a.143.143 0 0 0 .143-.14 5.32 5.32 0 0 1 1.568-3.648A5.32 5.32 0 0 1 8 9.747c1.432 0 2.776.558 3.789 1.57a5.32 5.32 0 0 1 1.568 3.649c.001.078.064.139.143.139h1.07a.143.143 0 0 0 .144-.146 6.7 6.7 0 0 0-.527-2.468m-6.188-4.1c-.82 0-1.59-.319-2.17-.9-.581-.58-.9-1.351-.9-2.17 0-.82.319-1.592.9-2.172.58-.58 1.35-.9 2.17-.9s1.592.32 2.172.9.9 1.352.9 2.171c0 .82-.32 1.591-.9 2.172-.58.58-1.352.9-2.172.9"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPeopleOther: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-people-other", props.className)} />
));

export default SvgPeopleOther;
