/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M22.715 3.43H7.072a.215.215 0 0 0-.214.215v1.5c0 .117.096.214.214.214h15.643a.215.215 0 0 0 .214-.214v-1.5a.215.215 0 0 0-.214-.215m0 7.607H7.072a.215.215 0 0 0-.214.215v1.5c0 .117.096.214.214.214h15.643a.215.215 0 0 0 .214-.214v-1.5a.215.215 0 0 0-.214-.215m0 7.608H7.072a.215.215 0 0 0-.214.214v1.5c0 .118.096.214.214.214h15.643a.215.215 0 0 0 .214-.214v-1.5a.215.215 0 0 0-.214-.215M1.072 4.395a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0m0 7.607a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0m0 7.607a1.5 1.5 0 1 0 2.999 0 1.5 1.5 0 0 0-2.999 0"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgUnorderedList: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-unordered-list", props.className)} />
));

export default SvgUnorderedList;
